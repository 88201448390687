import React from 'react'
import { motion } from 'framer-motion'

export default function PrivacyPolicy() {
    return (
        <>
            <div className='bg-black' style={{ paddingTop: "100px", paddingBottom: "85px" }}>
                <section class="body-font bg-black">
                    <div class="container mx-auto flex px-5 py-24 items-center justify-center text-center flex-col">
                        <div class="text-center lg:w-2/3 w-full">
                            <motion.h1 style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Privacy Policy</motion.h1>
                            <p style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">Welcome to Polibuzz! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our platform and services. By using Polibuzz, you consent to the practices described in this Privacy Policy.</p>
                        </div>
                    </div>
                </section>
            </div>
            <section class="bg-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Information We Collect</motion.h1>
                        <motion.div initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">When you create an account on Polibuzz, we collect the following information to set up and manage your account:
                            <br />
                            <ul>
                                <li style={{ listStyleType: "disc", paddingLeft: "15px" }}>Personal Information:
                                    <br />
                                    <ul style={{ listStyleType: "disc", paddingLeft: "25px" }}>When you create an account on Polibuzz, we collect the following information to set up and manage your account:
                                        <li>Full name</li>
                                        <li>Email Address</li>
                                        <li>Profile Picture (Optional)</li>
                                    </ul>
                                </li>
                                <br />
                            </ul>
                            <br />
                        </motion.div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    </div>
                </div>
            </section>
            <section class="bg-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    </div>
                    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <motion.h1 initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Use of Information</motion.h1>
                        <motion.div initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">We may use the information we collect for various purposes, including:
                            <br />
                            <ul style={{ listStyleType: "disc", paddingLeft: "25px" }}>
                                <li>Providing and maintaining our website and app.</li>
                                <li>Personalizing your experience with us.</li>
                                <li>Communicating with you, such as responding to inquiries or providing updates.</li>
                                <li>Analyzing usage of our website and app to improve its content and functionality.</li>
                                <li>Sending newsletters or promotional materials (if subscribed).</li>
                                <li>Protecting against unauthorized access or illegal activities.</li>
                            </ul>
                            <br />
                        </motion.div>
                    </div>
                </div>
            </section>
            <section class="bg-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Information Sharing and Disclosure</motion.h1>
                        <motion.div initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">We may disclose your information in the following circumstances:
                            <br />
                            <ul style={{ listStyleType: "disc", paddingLeft: "25px" }}>
                                <li>Third-Party Service Providers:<br />
                                    We may share your information with trusted third-party service providers who assist us in providing our platform and services. These service providers may include:
                                    <ul style={{ listStyleType: "disc", paddingLeft: "25px" }}>
                                        <li>Cloud hosting and Analytics providers</li>
                                        <li>Customer support providers</li>
                                    </ul>
                                </li>
                                <li>To comply with legal obligations.
                                    <ul style={{ listStyleType: "disc" }}>
                                    </ul>
                                </li>
                                <li>To protect our rights or property.
                                    <ul style={{ listStyleType: "disc" }}>
                                    </ul>
                                </li>
                                <li>To prevent or investigate possible wrongdoing.
                                    <ul style={{ listStyleType: "disc" }}>
                                    </ul>
                                </li>
                            </ul>
                            <br />
                        </motion.div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6"></div>
                </div>
            </section>
            <section class="bg-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    </div>
                    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <motion.h1 initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Data Security</motion.h1>
                        <motion.p initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">We take reasonable measures to protect the security of your personal information and implement appropriate technical and organizational safeguards. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.</motion.p>
                    </div>
                </div>
            </section>
            <section class="bg-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Third-Party Links</motion.h1>
                        <motion.p initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">Our website may contain links to third-party websites. We have no control over the content, privacy policies, or practices of these websites. We encourage you to review the privacy policies of any third-party websites you visit.</motion.p>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    </div>
                </div>
            </section>
            <section class="bg-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    </div>
                    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <motion.h1 initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Changes to This Privacy Policy</motion.h1>
                        <motion.p initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the revised Privacy Policy on our website.</motion.p>
                    </div>
                </div>
            </section>
            <section class="bg-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <motion.h1 initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Cookies and Similar Technologies</motion.h1>
                        <motion.p initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">We use cookies and similar technologies to enhance user experience, personalize content, and analyze user behavior. Cookies are small text files stored on your device when you access our platform. By using cookies, we can remember your preferences, provide relevant content, and improve our services. You can manage your cookie preferences through your browser settings.</motion.p>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    </div>
                </div>
            </section>
            <section class="bg-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    </div>
                    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <motion.h1 initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} style={{ fontSize: "40px", fontFamily: "Poppins" }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Analytics</motion.h1>
                        <motion.p initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.3 }} style={{ fontSize: "17.5px", fontFamily: "Poppins" }} class="mb-8 leading-relaxed text-gray-300">We may use third-party analytics tools to collect and analyze data about user interactions on our platform. This data helps us understand user behavior, improve our services, and enhance user experience.</motion.p>
                    </div>
                </div>
            </section>
        </>
    )
}
