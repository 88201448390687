import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { motion } from 'framer-motion';

export default function Footer() {
    return (
        <div className='bg-black'>
            <footer className="scrlbar">
                <div className="">
                    <div className="max-w-2xl mx-auto text-white py-10">
                        <div className="text-center">
                            <motion.h3 style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1.9 }} viewport={{ once: true }} className="text-3xl mb-3">Download the App!</motion.h3>
                            <motion.div transition={{ delay: 1.5 }} viewport={{ once: true }} className="flex justify-center my-10">
                                <a href='https://play.google.com/store/apps/details?id=com.polibuzz.latest'>
                                    <motion.div className="flex items-center border w-auto rounded-lg px-4 py-2 w-52 mx-2">
                                        <img src="https://cdn-icons-png.flaticon.com/512/888/888857.png" className="w-7 md:w-8" alt='' />
                                        <div className="text-left ml-3">
                                            <p className='text-xs text-gray-200'>GET IT ON </p>
                                            <p className="text-sm md:text-base"> Play Store </p>
                                        </div>
                                    </motion.div>
                                </a>
                                <motion.div className="flex items-center border w-auto rounded-lg px-4 py-2 w-44 mx-2">
                                    <img src="https://cdn-icons-png.flaticon.com/512/888/888841.png" className="w-7 md:w-8" alt='' />
                                    <div className="text-left ml-3">
                                        <p className='text-xs text-gray-200'>Coming soon on </p>
                                        <p className="text-sm md:text-base"> Apple Store </p>
                                    </div>
                                </motion.div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="text-gray-600 body-font">
                <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
                    <Link className="flex title-font font-medium items-center md:justify-start justify-center text-white" to="/">
                        <img src="../favicon.ico" height="50px" width="50px" alt='' />
                        <span className="ml-3 text-xl">Polibuzz</span>
                    </Link>
                    <p className="text-sm text-gray-400 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2023 Polibuzz</p>
                    <Link to="/">
                        <motion.p className="text-sm text-gray-400 sm:pl-4 sm:py-2 sm:mt-0 mt-4">Terms and Conditions</motion.p>
                    </Link>
                    <Link to="/privacypolicy">
                        <motion.p className="text-sm text-gray-400 sm:pl-4 sm:py-2 sm:mt-0 mt-4">Privacy Policy</motion.p>
                    </Link>
                    <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                        <a className="ml-3 text-white" href="https://twitter.com/PolibuzzIndia">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                            </svg>
                        </a>
                        <a className="ml-3 text-white" href="https://www.instagram.com/">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                            </svg>
                        </a>
                        <a className="ml-3 text-white" href="https://www.linkedin.com/company/polibuzz/">
                            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
                                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                <circle cx="4" cy="4" r="2" stroke="none"></circle>
                            </svg>
                        </a>
                    </span>
                </div>
            </footer>
        </div>
    )
}
