import './styles/app.component.css';
import "./styles/scrollbar.css";
import Team from './Components/Team';
import Home from './Components/Home';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Aboutus from './Components/Aboutus';
import Contact from './Components/Contact';
import { animateScroll } from 'react-scroll';
import PrivacyPolicy from './Components/PrivacyPolicy';

function App() {
  const scrollToTop = () => {
    animateScroll.scrollToTop();
  }
  return (
    <Router onUpdate={scrollToTop}>
      <header className="header1 sticky top-0 flex flex-wrap items-center justify-between px-4 md:px-8 py-2 bg-blue-900" style={{ opacity: "100", zIndex: 90 }}>
        <nav className="header1 flex justify-around py-4 bg-black backdrop-blur-md shadow-md w-full top-0 left-0 right-0 z-100" style={{ position: 'fixed', paddingLeft: "5px", paddingRight: "5px", zIndex: 100 }}>
          <div className="flex px-0">
            <Link className="cursor-pointer px-0" to="/">
              <h3 className="items-center text-2xl font-medium text-white lg:flex" style={{ fontFamily: "poppins", fontWeight: "600" }}>&nbsp;&nbsp;
                <img className="h-10 object-cover" src="../favicon.ico" alt='' />&nbsp;Polibuzz
              </h3>
            </Link>
          </div>
          <div className="md:ml-auto md:mr-auto items-center hidden space-x-8 lg:flex">
            <Link className="mr-5 flex text-white transition-colors duration-300" style={{ fontFamily: "poppins" }}></Link>
            <Link className="mr-5 flex text-white transition-colors duration-300" style={{ fontFamily: "poppins" }}></Link>
            <Link className="mr-5 flex text-white transition-colors duration-300" style={{ fontFamily: "poppins" }} to="/">Home</Link>
            <Link className="mr-5 flex text-white transition-colors duration-300" style={{ fontFamily: "poppins" }} to="/team">Team</Link>
            <Link className="mr-5 flex text-white transition-colors duration-300" style={{ fontFamily: "poppins" }} to="/about">About Us</Link>
          </div>
          <div className="flex items-center space-x-7">
            <a className="flex text-white cursor-pointer transition-colors duration-300" style={{ fontFamily: "poppins" }} href="https://www.linkedin.com/company/polibuzz/">
              <i className="fa-brands fa-linkedin-in py-1"></i>&nbsp;&nbsp;Linkedin
            </a>
            <a className="flex text-white cursor-pointer transition-colors duration-300" style={{ fontFamily: "poppins" }} href="https://twitter.com/PolibuzzIndia">
              <i className="fa-brands fa-twitter py-1"></i>&nbsp;&nbsp;Twitter
            </a>
            <Link className="flex text-white cursor-pointer transition-colors duration-300" to="/contact" style={{ fontFamily: "poppins" }}>Contact Us</Link>&nbsp;&nbsp;
          </div>
        </nav>
      </header>
      <Switch>
        <Route exact path="/" render={() => {
          return (<><Home /></>)
        }}>
        </Route>
        <Route exact path="/team">
          <Team />
        </Route>
        <Route exact path="/about">
          <Aboutus />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;