import React, { useRef, useState, useEffect } from 'react'
import '../styles/home.component.css'
import { motion, useScroll, useTransform } from 'framer-motion'
import emailjs from '@emailjs/browser'
import Image1 from '../Images/black.png'
import Image2 from "../Images/logo.png"
//import CubeFigure from './animatedCube';

export const HorizontalScroll = () => {
    const [expand, setexpand] = useState(false);
    const [expand1, setexpand1] = useState(false);
    const [expand2, setexpand2] = useState(false);
    const [expand3, setexpand3] = useState(false);
    const [expand4, setexpand4] = useState(false);
    const [expand5, setexpand5] = useState(false);
    return (
        <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap', padding: '20px' }}>
            <div style={{ display: 'inline-block', marginRight: '20px', height: "500px" }}>
                <motion.div transition={{ layout: { duration: 0.3 } }} layout onMouseEnter={() => setexpand(!expand)} onMouseOut={() => setexpand(expand)} onMouseLeave={() => setexpand(expand)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <i className="fa-solid fa-user-group"></i>
                    </div>
                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Discussion forums and comment sections</motion.h2>
                    {expand && (
                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Engage in dialogue with political leaders and other people in the community.</motion.p>)}
                </motion.div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px', height: "500px" }}>
                <motion.div transition={{ layout: { duration: 0.3 } }} layout onMouseEnter={() => setexpand1(!expand1)} onMouseOut={() => setexpand1(expand1)} onMouseLeave={() => setexpand1(expand1)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <i className="fa fa-square-poll-vertical"></i>
                    </div>
                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Polls and surveys</motion.h2>
                    {expand1 && (
                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Let your leaders know what you seek. Give them your invaluable opinion.</motion.p>)}
                </motion.div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px', height: "500px" }}>
                <motion.div transition={{ layout: { duration: 0.3 } }} layout onMouseEnter={() => setexpand2(!expand2)} onMouseOut={() => setexpand2(expand2)} onMouseLeave={() => setexpand2(expand2)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <i className="fa-regular fa-newspaper"></i>
                    </div>
                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Subscribe to newsletters or alerts</motion.h2>
                    {expand2 && (
                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Receive updates about important political news and updates from your favourite sources.</motion.p>)}
                </motion.div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px', height: "500px" }}>
                <motion.div transition={{ layout: { duration: 0.3 } }} layout onMouseEnter={() => setexpand3(!expand3)} onMouseOut={() => setexpand3(expand3)} onMouseLeave={() => setexpand3(expand3)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <i className="fa-solid fa-user-plus"></i>
                    </div>
                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Create and join groups and communities</motion.h2>
                    {expand3 && (
                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Become a part of like-minded individuals to discuss various political issues and topics.</motion.p>)}
                </motion.div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px', height: "500px" }}>
                <motion.div transition={{ layout: { duration: 0.3 } }} layout onMouseEnter={() => setexpand4(!expand4)} onMouseOut={() => setexpand4(expand4)} onMouseLeave={() => setexpand4(expand4)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                    <div className="text-yellow-500" fill="none" viewBox="0 0 25 25" stroke="currentColor">
                        <i className="fa-solid fa-earth-americas"></i>
                    </div>
                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Organising online and offline events</motion.h2>
                    {expand4 && (
                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Keep track of political events like debates, elections, conferences or live streams.</motion.p>)}
                </motion.div>
            </div>
            <div style={{ display: 'inline-block', marginRight: '20px', height: "500px" }}>
                <motion.div transition={{ layout: { duration: 0.3 } }} layout onMouseEnter={() => setexpand5(!expand5)} onMouseOut={() => setexpand5(expand5)} onMouseLeave={() => setexpand5(expand5)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <i className="fa-solid fa-users-rays" height="20px" width="20px"></i>
                    </div>
                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Establish credibility within the community.</motion.h2>
                    {expand5 && (
                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Users are rewarded for high-quality contributions in the community which helps them to grown in the political landscape.</motion.p>)}
                </motion.div>
            </div>
        </div >
    );
};

export const EmailResponse = () => {
    const [formData, setFormData] = useState({
        email_id: ''
    });
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_d301nlr', 'template_e34ouvb', form.current, '2O-QkD3nwlPXkckvF')
            .then((result) => {
                console.log(result.text);
                setFormData({ email_id: '' });
                setIsSubmitted(true);
            }, (error) => {
                console.log(error.text);
            });
    };
    //eslint-disable-next-line
    const [isSubmitted, setIsSubmitted] = useState(false);
    //eslint-disable-next-line
    const handleClosePopup = () => {
        setIsSubmitted(false);
    };
    return (
        <section className="text-gray-600 body-font">
            <form ref={form} onSubmit={sendEmail}>
                <div className="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
                    <motion.div className="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
                        <motion.h1 style={{ fontFamily: "poppins", fontWeight: "600" }} initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1.2 }} viewport={{ once: true }} className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Want to be one of our first users?</motion.h1>
                        <motion.p style={{ fontFamily: "poppins", fontWeight: "600" }} initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1.2, delay: 0.5 }} viewport={{ once: true }} className="mb-8 leading-relaxed text-gray-300">Drop your email down below to get notified!</motion.p>
                        <div className="flex w-full justify-center items-end">
                            <div className="relative mr-4 lg:w-full xl:w-1/2 w-2/4 md:w-full text-left">
                                <motion.input required value={formData.email_id} onChange={(e) => setFormData({ ...formData, email_id: e.target.value })} name="email_id" type="email" initial={{ opacity: 0, y: -20 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1.2 }} viewport={{ once: true }} placeholder="Email Address" id="hero-field" className="w-full bg-black bg-opacity-50 h-14 rounded focus:ring-2 focus:ring-white focus:bg-transparent border border-gray-300 focus:border-white text-white outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                            <motion.button type='submit' value="Send" initial={{ opacity: 0, y: -20 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1.2, delay: 0.3 }} viewport={{ once: true }} className="inline-flex text-white bg-gradient-to-br from-[#FC7300] to-[#54B435] border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 h-14 items-center rounded text-lg">Submit</motion.button>
                        </div>
                    </motion.div>
                    <br />
                </div>
            </form>
        </section>
    );
};

export const SuccessPopup = ({ onClose }) => {
    return (
        <div className="success-popup">
            <h2>Submitted Successfully!!</h2>
            <p>Your form has been submitted successfully.</p>
            <button onClick={onClose}>Close</button>
        </div>
    );
};

export default function Home() {
    const [expand, setexpand] = useState(false);
    const [expand1, setexpand1] = useState(false);
    const [expand2, setexpand2] = useState(false);
    const [expand3, setexpand3] = useState(false);
    const [expand4, setexpand4] = useState(false);
    const [expand5, setexpand5] = useState(false);
    let { scrollYProgress } = useScroll();
    const sectionRef = useRef(null);
    const scrollToSection = () => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    let parallax_y = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);
    let parallax_z = useTransform(scrollYProgress, [0, 1], ["0%", "200%"]);
    const scrolldown = useRef(null);
    //eslint-disable-next-line
    const [scrollPosition, setScrollPosition] = useState(0);
    const scrollToRef = () => {
        scrolldown.current.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className="text-gray-600 body-font scrlbar bg-black">
                <section style={{ parallax_z }} className='Imgbg'>
                    <motion.div>
                        <motion.img initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1.7 }} src={Image1} alt="" className="background-image" />
                        <motion.div style={{ parallax_z, paddingTop: "200px" }} className="diff container mx-auto flex px-1 py-24 md:flex-col items-center">
                            <motion.div className="w-4/5 lg:h-2/4 lg:pl-24 md:pl-2 flex flex-row md:items-center md:text-center items-center text-center">
                                <motion.img className='items-start logo' src={Image2} alt="" height={"250px"} width={"250px"} />
                                <motion.h1 initial={{ opacity: 0, x: 15 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.75 }} whileHover={{ scale: 1.01 }} className="imgtext title-font md:text-9xl mb-4 font-medium text-white items-center" style={{ fontFamily: "Poppins", fontWeight: "600" }}>Polibuzz</motion.h1>
                            </motion.div>
                            <motion.div initial={{}} whileInView={{}} transition={{}} className='text-gray-400 learn flex-row' style={{ alignItems: "end", textAlign: "end", paddingTop: "100px" }}>
                                <motion.button onClick={scrollToSection} initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.65, delay: 0.3 }} className='hover:text-white'>Learn More&nbsp;&nbsp;
                                    <i className="fa-solid fa-angle-down"></i>
                                </motion.button>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </section>
                <section style={{ parallax_y }} className="text-gray-600 bg-body body-font scrlbar1 items-center rightPadding">
                    <motion.div ref={sectionRef} style={{ paddingRight: "5px" }} className="container mx-auto flex px-24 w-5/6 py-28 md:flex-row flex-col text-center items-center">
                        <motion.div style={{ paddingBottom: "150px" }} className="lg:flex-grow w-3/5 lg:pr-24 md:pr-18 flex flex-col md:items-center md:text-center mb-24 md:mb-8 items-center text-center topPadding" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.8 }}>
                            <motion.p style={{ fontFamily: "poppins", fontWeight: "500" }} className="mb-8 leading-relaxed sec02 text-grey-600" initial={{ opacity: 0, x: 75 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.8 }}>For India, By India</motion.p>
                            <motion.h1 style={{ fontFamily: "poppins", fontWeight: "600" }} className="title-font mb-4 text-white sec01" initial={{ opacity: 0, x: -75 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 1, delay: 0.7 }}>A New Age of Politics</motion.h1>
                            <motion.p style={{ fontFamily: "poppins", fontWeight: "500" }} className="mb-8 leading-relaxed sec02 text-center text-gray-400" initial={{ opacity: 0, x: 75 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.8, delay: 1 }}>Polibuzz is a social media platform focused on Indian politics, providing a centralized nexus for political engagement.</motion.p>
                            <br />
                            <button onClick={scrollToRef} className="bg-gradient-to-br from-[#FC7300] to-[#54B435] px-5 py-3 text-base font-medium text-white transition duration-200 button1 hover:shadow-lg hover:shadow-white/20" style={{ borderRadius: '10px', height: '60px' }}>See What's Happening</button>
                        </motion.div>
                    </motion.div>
                </section>
                <div className=''>
                    <section className="">
                        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-12">
                            <div className="mx-auto max-w-lg text-center text-white">
                                <motion.h1 className="text-3xl font-bold sm:text-4xl text-center headertext" initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }} viewport={{ once: true }} style={{ fontFamily: "poppins", fontWeight: "600" }}>Join The Movement</motion.h1>
                                <motion.p className="mt-4 text-gray-300 headersubtext text-center" initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.6, delay: 0.4 }} viewport={{ once: true }} style={{ fontFamily: "poppins", fontWeight: "500" }}>India is the world's largest democracy with 900 million eligible voters. Help us revolutionise the way people connect with their leaders.</motion.p>
                            </div>
                            <br />
                            <div className="mt-8 grid gap-8 lg:grid-cols-3 container">
                                <motion.div transition={{ layout: { duration: 0.25 } }} layout onMouseEnter={() => setexpand(!expand)} onMouseOut={() => setexpand(expand)} onMouseLeave={() => setexpand(expand)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <i className="fa-solid fa-user-group"></i>
                                    </div>
                                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Discussion forums and comment sections</motion.h2>
                                    {expand && (
                                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Engage in dialogue with political leaders and other people in the community.</motion.p>)}
                                </motion.div>
                                <motion.div transition={{ layout: { duration: 0.25 } }} layout onMouseEnter={() => setexpand1(!expand1)} onMouseOut={() => setexpand1(expand1)} onMouseLeave={() => setexpand1(expand1)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <i className="fa fa-square-poll-vertical"></i>
                                    </div>
                                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Polls and surveys</motion.h2>
                                    {expand1 && (
                                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Let your leaders know what you seek. Give them your invaluable opinion.</motion.p>)}
                                </motion.div>
                                <motion.div transition={{ layout: { duration: 0.25 } }} layout onMouseEnter={() => setexpand2(!expand2)} onMouseOut={() => setexpand2(expand2)} onMouseLeave={() => setexpand2(expand2)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <i className="fa-regular fa-newspaper"></i>
                                    </div>
                                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Subscribe to newsletters or alerts</motion.h2>
                                    {expand2 && (
                                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Receive updates about important political news and updates from your favourite sources.</motion.p>)}
                                </motion.div>
                                <motion.div transition={{ layout: { duration: 0.25 } }} layout onMouseEnter={() => setexpand3(!expand3)} onMouseOut={() => setexpand3(expand3)} onMouseLeave={() => setexpand3(expand3)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <i className="fa-solid fa-user-plus"></i>
                                    </div>
                                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Create and join groups and communities</motion.h2>
                                    {expand3 && (
                                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Become a part of like-minded individuals to discuss various political issues and topics.</motion.p>)}
                                </motion.div>
                                <motion.div transition={{ layout: { duration: 0.25 } }} layout onMouseEnter={() => setexpand4(!expand4)} onMouseOut={() => setexpand4(expand4)} onMouseLeave={() => setexpand4(expand4)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                                    <div className="text-yellow-500" fill="none" viewBox="0 0 25 25" stroke="currentColor">
                                        <i className="fa-solid fa-earth-americas"></i>
                                    </div>
                                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Organising online and offline events</motion.h2>
                                    {expand4 && (
                                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Keep track of political events like debates, elections, conferences or live streams.</motion.p>)}
                                </motion.div>
                                <motion.div transition={{ layout: { duration: 0.25 } }} layout onMouseEnter={() => setexpand5(!expand5)} onMouseOut={() => setexpand5(expand5)} onMouseLeave={() => setexpand5(expand5)} className="scroll rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-yellow-500/10 hover:shadow-yellow-500/10">
                                    <div className="text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <i className="fa-solid fa-users-rays" height="20px" width="20px"></i>
                                    </div>
                                    <motion.h2 layout="position" className="mt-4 text-xl font-bold text-white " style={{ fontFamily: "poppins" }}>Establish credibility within the community.</motion.h2>
                                    {expand5 && (
                                        <motion.p style={{ fontFamily: "poppins" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.25 }} exit={{ opacity: 0 }} className="mt-1 text-sm text-gray-300">Users are rewarded for high-quality contributions in the community which helps them to grown in the political landscape.</motion.p>)}
                                </motion.div>
                            </div>
                        </div>
                    </section>
                </div>
                <br />
                <br />
                <br />
                <div className='' ref={scrolldown}>
                    <section className="text-gray-600 body-font parallax">
                        <motion.div className="container mx-auto flex px-20 py-24 md:flex-row flex-col items-center">
                            <motion.div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center" initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.7 }}>
                                <h1 style={{ fontFamily: "poppins", fontWeight: "600" }} className="title-font sm:text-4xl text-7xl mb-4 text-white parallax1">What's buzzing
                                    <br className="parallax1" />near you?
                                </h1>
                            </motion.div>
                            <motion.div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6" initial={{ opacity: 0, x: 80 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.9 }} exit={{ opacity: 0, x: 80 }}>
                                <img className="object-cover parallax2 object-center rounded border-yellow-500/10 shadow-yellow-500/10" src="" height={""} width={""} alt='' />
                            </motion.div>
                        </motion.div>
                    </section>
                    <br />
                    <br />
                    <br />
                    <section className="text-gray-600 body-font">
                        <motion.div className="container mx-auto flex px-20 py-24 md:flex-row flex-col items-center">
                            <motion.div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                                <img className="object-cover parallax2 object-center rounded border-yellow-500/10 shadow-yellow-500/10" height={""} width={""} src="" alt='' />
                            </motion.div>
                            <motion.div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center" initial={{ opacity: 0, x: 80 }} whileInView={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 80 }} transition={{ duration: 0.7 }}>
                                <h1 style={{ fontFamily: "poppins", fontWeight: "600" }} className="title-font sm:text-4xl text-7xl mb-4 text-white parallax1">Want to start your political journey?
                                    <br />
                                    <br className="parallax1" />You are at the right place.
                                </h1>
                            </motion.div>
                        </motion.div>
                    </section>
                    <br />
                    <br />
                    <br />
                    <section className="text-gray-600 body-font">
                        <motion.div className="container mx-auto flex px-20 py-24 md:flex-row flex-col items-center">
                            <motion.div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center" initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }} transition={{ duration: 0.7 }}>
                                <h1 style={{ fontFamily: "poppins", fontWeight: "600" }} className="title-font sm:text-4xl text-7xl mb-4 text-white parallax1">Connect with your
                                    <br className="parallax1" />local leaders.
                                </h1>
                            </motion.div>
                            <motion.div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                <img className="object-cover parallax2 object-center rounded border-yellow-500/10 shadow-yellow-500/10" height={""} width={""} src="" alt='' />
                            </motion.div>
                        </motion.div>
                    </section>
                    <br />
                    <br />
                    <br />
                    <section className="text-gray-600 body-font">
                        <motion.div className="container mx-auto flex px-20 py-24 md:flex-row flex-col items-center">
                            <motion.div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0" initial={{ opacity: 0, x: -150 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.8 }} exit={{ opacity: 0, x: -150 }}>
                                <img className="object-cover parallax2 object-center rounded border-yellow-500/10 shadow-yellow-500/10" height={""} width={""} src="" alt='' />
                            </motion.div>
                            <motion.div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center" initial={{ opacity: 0, x: 80 }} whileInView={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 80 }} transition={{ duration: 0.7 }}>
                                <h1 style={{ fontFamily: "poppins", fontWeight: "600" }} className="title-font sm:text-4xl text-7xl mb-4 text-white parallax1">Express yourself where people
                                    <br className="parallax1" style={{ backgroundColor: "black" }} />listen
                                </h1>
                            </motion.div>
                        </motion.div>
                    </section>
                </div>
                <br />
                <EmailResponse />
            </div>
        </>
    )
}