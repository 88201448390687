import React from 'react'
import { motion } from 'framer-motion'

export default function Aboutus() {
  return (
    <div className='bg-black'>
      <br style={{ backgroundColor: 'black' }} />
      <br style={{ backgroundColor: 'black' }} />
      <br style={{ backgroundColor: 'black' }} />
      <br style={{ backgroundColor: 'black' }} />
      <br style={{ backgroundColor: 'black' }} />
      <section class="body-font">
        <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div class="text-center lg:w-2/3 w-full">
            <motion.h1 style={{ fontFamily: "poppins" }} initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">About Us</motion.h1>
            <motion.p style={{ fontSize: "25px", fontFamily: "poppins" }} initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="text-gray-300 mb-8 leading-relaxed">Welcome to Polibuzz! We are a bunch of passionate and quirky folks who are on a mission to create a social media platform that makes politics accessible, engaging, and fun for all. We believe that everyone has a right to participate in politics, and our platform aims to make this possible by providing a safe and welcoming space where people can connect, learn, and collaborate towards a better future.</motion.p>
          </div>
        </div>
      </section>
      <br />
      <br />
      <section class="body-font">
        <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div class="text-center lg:w-2/3 w-full">
            <motion.h1 style={{ fontFamily: "poppins" }} initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Our Vision</motion.h1>
            <motion.p style={{ fontSize: "25px", fontFamily: "poppins" }} initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="text-gray-300 mb-8 leading-relaxed">To empower Indian citizens with a platform that provides easy access to information, encourages participation in political discussions, and facilitates connections between citizens and their representatives. We envision a platform that promotes transparency, accountability, and inclusivity in Indian politics, and helps build a stronger democracy for all.</motion.p>
          </div>
        </div>
      </section>
      <br />
      <section class="body-font">
        <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div class="text-center lg:w-2/3 w-full">
            <motion.h1 style={{ fontFamily: "poppins" }} initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Our Mission</motion.h1>
            <motion.p style={{ fontSize: "25px", fontFamily: "poppins" }} initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="text-gray-300 mb-8 leading-relaxed">Our mission is to create a social media platform that is tailored to the specific needs of Indian citizens who are interested in politics. We believe that politics should be accessible and understandable to everyone, and our platform aims to make this possible by providing personalized newsfeeds, discussion forums, polls, and other features that encourage engagement and facilitate learning. Our goal is to create a safe, respectful, and open space where people can express their opinions and ideas, learn from others, and collaborate towards meaningful change.</motion.p>
          </div>
        </div>
      </section>
      <section class="body-font">
        <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div class="text-center lg:w-2/3 w-full">
            <motion.h1 style={{ fontFamily: "poppins" }} initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Our Values</motion.h1>
            <motion.p style={{ fontSize: "25px", fontFamily: "poppins" }} initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="text-gray-300 mb-8 leading-relaxed">At our platform, we value integrity, transparency, inclusivity, and innovation. We are committed to upholding the highest standards of ethical conduct, and to providing our users with accurate and unbiased information. We believe that every voice matters, and we strive to create a platform that is welcoming and respectful to people of all backgrounds, beliefs, and identities. We are dedicated to continuous learning and improvement, and we constantly seek new and creative ways to enhance our platform and serve our users better.</motion.p>
          </div>
        </div>
      </section>
      <section class="body-font">
        <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div class="text-center lg:w-2/3 w-full">
            <motion.h1 style={{ fontFamily: "poppins" }} initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Join Us</motion.h1>
            <motion.p style={{ fontSize: "25px", fontFamily: "poppins" }} initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} class="text-gray-300 mb-8 leading-relaxed">We invite you to join us on our journey to transform Indian politics. Whether you are a citizen, politician, or organization, we believe that you have a role to play in building a better future for our country. Together, we can create a platform that amplifies our voices, fosters collaboration, and inspires positive change. Join us today and become a part of the movement towards a stronger democracy for all.</motion.p>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
    </div>
  )
}
