import React from 'react'
import '../styles/Team.css';
import { motion } from 'framer-motion';
//import Image from "../Images/me.jpeg";
//import Image2 from "../Images/aditya.jpeg";

export default function Team() {
  return (
    <motion.div className='bg-black'>
      <br style={{ backgroundColor: 'black' }} />
      <br style={{ backgroundColor: 'black' }} />
      <br style={{ backgroundColor: 'black' }} />
      <br style={{ backgroundColor: 'black' }} />
      <br style={{ backgroundColor: 'black' }} />
      <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4">
        <motion.div className="text-center pb-12">
          <motion.h1 style={{ fontFamily: "poppins" }} initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.65 }} viewport={{ once: true }} className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-white">Our Team</motion.h1>
        </motion.div>
        <section className="text-gray-600 body-font">
          <motion.div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <motion.div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <motion.img initial={{ opacity: 0, x: -10 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 2 }} className="object-cover object-center rounded" style={{ borderRadius: "1000px" }} src={process.env.PUBLIC_URL + '/Images/aditya.jpeg'} height={"450px"} width={"450px"} alt='' />
            </motion.div>
            <motion.div className="lg:flex-grow md:w-2/4 lg:pl-24 md:pl-15 flex flex-col md:items-start md:text-left">
              <motion.h1 style={{ fontFamily: "poppins" }} initial={{ opacity: 0, x: 90 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.65 }} className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Aditya Pandey</motion.h1>
              <motion.div initial={{ opacity: 0, x: 90 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.65, delay: 0.5 }} className="flex flex-row items-center">
                <motion.p class="text-gray-300">(Co Founder, CEO)</motion.p>
                <a class="ml-3 text-white" href='https://www.linkedin.com/in/pandeyaditya03/'>
                  <i class="fa-brands fa-linkedin"></i>
                </a>
              </motion.div>
              <motion.p initial={{ opacity: 0, x: 90 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.65, delay: 0.9 }} style={{ fontFamily: "poppins" }} className="mb-8 leading-relaxed text-gray-500">A driven BTech student in Computer Science and Artificial Intelligence, I am passionate about innovation, entrepreneurship, and the intersection of technology and business</motion.p>
            </motion.div>
          </motion.div>
        </section>
        <section className="text-gray-600 body-font">
          <motion.div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <motion.div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0">
              <motion.h1 style={{ fontFamily: "poppins" }} initial={{ opacity: 0, x: -90 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.65 }} className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Divy Parikh</motion.h1>
              <motion.div initial={{ opacity: 0, x: -90 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.65, delay: 0.5 }} className="flex flex-row items-center">
                <motion.p class="text-gray-300">(Co Founder, CTO)</motion.p>
                <a class="ml-3 text-white" href='https://www.linkedin.com/in/divyparikh611/'>
                  <i class="fa-brands fa-linkedin"></i>
                </a>
              </motion.div>
              <motion.p initial={{ opacity: 0, x: -90 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 0.65, delay: 0.9 }} style={{ fontFamily: "poppins" }} className="mb-8 leading-relaxed text-gray-500">I am a full-stack developer, pursuing BTech in Electronics and Communications Engineering. I am passionate about technology, entrepreneurship, sports, and music. I constantly look forward to new opportunities to apply my skills and acquire new ones.</motion.p>
            </motion.div>
            <motion.div className="lg:max-w-lg lg:w-full md:w-1/2 w-3/6" style={{ alignContent: "right" }}>
              <motion.img initial={{ opacity: 0, x: 10 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 2 }} className="object-cover object-center rounded" style={{ borderRadius: "1000px" }} src={process.env.PUBLIC_URL + '/Images/me.jpeg'} height={'450px'} width={'450px'} alt='' />
            </motion.div>
          </motion.div>
        </section>
      </section>
    </motion.div>
  )
  //eslint-disable-next-line
  {/*src={process.env.PUBLIC_URL + '/aditya.jpeg'}*/ }
  //eslint-disable-next-line
  {/*src={process.env.PUBLIC_URL + '/me.jpeg'}*/ }
}
